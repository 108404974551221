<template>

	<template v-if="interval!='custom_date_range'">
		<o-field variant="primary">
			<o-select size="small" v-model="interval" @change="changedInterval(true)">
				<option v-for="(item, key) in intervals" :value="key">
					{{ item }}
				</option>
			</o-select>
		</o-field>
	</template>

	<template v-else>
		<o-field variant="primary" class="datepicker">
			<o-datepicker ref="datepicker" v-model="date_picker" @active-change="changedInterval(false)" variant="primary" size="small" trap-focus range :firstDayOfWeek=1 />
		</o-field>
		<o-button @click="interval='today';changedInterval(false)" icon-left="close" variant="primary" size="small" />
	</template>

</template>

<script>
	export default {
		data() {
			return {
				interval: 'today',
				intervals: {
					'today':'Сегодня',
					'yesterday':'Вчера',
					'first_day_of_this_week':'Текущая неделя', // last_monday ?
					'7_days_ago':'Последние 7 дней',
					'first_day_of_this_month':'Текущий месяц',
					'previous_month':'Предыдущий месяц',
					'1_month_ago':'Последние 30 дней',
					'first_day_of_this_year':'Текущий год',
					'all_time':'За все время',
					'custom_date_range':'Интервал дат',
				},
				date_picker: [],
				monthes: {},
			}
		},
		emits: ['change'],
		props: ['type'],
		methods: {
			changedInterval(show) {
				if(this.type != 'month') {
					if(this.interval == 'custom_date_range') {
						if(show) this.$refs.datepicker.toggle()
						if(this.date_picker[1]) this.$emit('change', {'interval':this.interval, 'from':this.$refs.datepicker.formatYYYYMMDD(this.date_picker[0])+' 00:00', 'to':this.$refs.datepicker.formatYYYYMMDD(this.date_picker[1])+' 23:59'})
					} else {
						this.$emit('change', {'interval':this.interval})
					}
				} else {
					let date = new Date(this.interval)
					let last = this.getDateISO(date) + ' 23:59:59'
					date.setMonth(date.getMonth(), 1);
					let first = this.getDateISO(date) + ' 00:00:00'
					this.$emit('change', {'interval':'custom_date_range', 'from':first, 'to':last})
				}
			},
			getDateISO(date) {
				var tzoffset = (new Date()).getTimezoneOffset() * 60000
				date = new Date(date - tzoffset)
				return(date.toISOString().split('T')[0])
			},
		},
		mounted() {
			if(this.type == 'month') {
				this.intervals = {}
				const date = new Date
				this.interval = this.getDateISO(date)
				while(date.getFullYear() >= 2023) {
					this.intervals[this.getDateISO(date)] = date.toLocaleString('ru', {year:'numeric', month:'long'})
					date.setMonth(date.getMonth(), 0);
				}
				this.changedInterval(false)
			}
		},
	}
</script>

<style>
.datepicker .dropdown-menu{ left:inherit; right:0; }
</style>
