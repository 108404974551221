<template>

	<div>
		<o-field variant="primary">
			<o-button @click="this.add()" variant="primary" outlined size="small">
				<span v-if="!this.addForm">Добавить</span>
				<span v-if="this.addForm">Сохранить</span>
			</o-button>
			<template v-if="this.addForm">
				<o-button @click="addForm=false, this.data.shift()" variant="warning" outlined size="small">Отмена</o-button>
			</template>
		</o-field>

		<o-table :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true>
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">

				<template v-if="column.field=='active'">
					<o-icon pack="fas" :icon="row.active==1?'check':'xmark'" size="small" :variant="row.active==1?'primary':'warning'" @click="active(row.id)" />
				</template>

				<template v-else-if="this.edit==column.field+row.id || (this.addForm && row.id==-1)">

					<o-input v-model="row[column.field]" :ref="column.field" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)" @keyup.enter="send(row, column.field)" size="small" variant="primary" />

				</template>

				<template v-else-if="!row[column.field]">
					<div @click="this.focus(row, column.field)" class="empty"></div>
				</template>
				<template v-else>
					<span @click="this.focus(row, column.field)">{{ this.rowValue(row, column.field) }}</span>
				</template>


			</o-table-column>
		</o-table>

	</div>
</template>

<script>
	export default {
		name: 'SendBranches',
		data() {
			return {
				columns: [
					{ field:'branch', label:'Логин ПП' },
				],
				data: [],
				edit: '',
				prev: '',
				addForm: false,
			}
		},
		methods: {
			rowValue(row, field) {
				return (field == 'aff_id') ? this.branches[row[field]].branch : row[field]
			},
			active(id) {
				this.$root.loadData({'id':id}, this, (dt) => {
					this.data = dt['branches']
				})
			},
			send(row, field) {
				if(!this.addForm)
					this.$root.loadData({'id':row.id, 'field':field, 'value':row[field]}, this, (dt) => {
						this.data = dt['branches']
						this.edit = ''
					})
			},
			add() {
				this.edit = '';
				if(!this.addForm) {
					const newRow = {id:-1, active:1, aff_id:Object.keys(this.affs)[0], branch:this.branches[0], server:this.servers[0], }
					this.data.unshift(newRow)
				} else {
					var row = this.data[0]
					this.$root.loadData(row, this, (dt) => {
						this.data = dt['branches']
					})
				}
				this.addForm = !this.addForm
			},
			focus(row, field) {
				this.prev = row[field]
				this.edit = field + row.id
				if(this.addForm) {
					this.data.shift()
					this.addForm = false
				}
			},
			blur(row, field) {
				if(!this.addForm) {
					row[field] = this.prev
					this.edit = ''
				}
			},
		},
		mounted() {
			this.$root.loadData(null, this, (dt) => {
				this.data = dt['branches']
			})
		},
	}

</script>

<style>

</style>
