<template>

	<div class="send-resend">

		<o-field variant="primary">
			<o-button @click="this.add()" variant="primary" outlined size="small">
				<span v-if="!this.addForm">Добавить</span>
				<span v-if="this.addForm">Сохранить</span>
			</o-button>
			<template v-if="this.addForm">
				<o-button @click="addForm=false, this.data.shift()" variant="warning" outlined size="small">Отмена</o-button>
			</template>
		</o-field>

		<o-table :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true>
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">

				<template v-if="column.field=='active'">
					<o-icon pack="fas" :icon="row.active==1?'check':'xmark'" size="small" :variant="row.active==1?'primary':'warning'" @click="active(row.id)" />
				</template>

				<template v-else-if="column.field=='send'">
					<o-icon pack="fas" icon="play" size="small" :variant="row.active==1?'primary':'warning'" @click="testSend(row.id)" />
				</template>

				<template v-else-if="this.edit==column.field+row.id || (this.addForm && row.id==-1)">

					<template v-if="column.field=='target_offer_id'">
						<o-field variant="primary"><o-select size="small" v-model="row[column.field]" @change="send(row, column.field)" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)">
							<option v-for="item in target_offers" :value="item.id">{{ item.offer }}</option>
						</o-select></o-field>
					</template>
					<template v-else-if="column.field=='month'">
						<o-field variant="primary"><o-select size="small" v-model="row[column.field]" @change="send(row, column.field)" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)">
							<option v-for="item in this.monthes" :value="item">{{ item }}</option>
						</o-select></o-field>
					</template>
					<template v-else-if="column.field=='src_offer'">
						<o-field variant="primary"><o-select size="small" v-model="row[column.field]" @change="send(row, column.field)" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)">
							<option v-for="item in src_offers" :value="item.src_offer">{{ item.src_offer }}</option>
						</o-select></o-field>
					</template>
					<template v-else-if="column.field=='status_id'">
						<o-field variant="primary"><o-select size="small" v-model="row[column.field]" @change="send(row, column.field)" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)">
							<option v-for="(item, key) in this.$root.statuses" :value="key">
								{{ item }}
							</option>
						</o-select></o-field>
					</template>
					<template v-else-if="column.field=='percent'">
						<o-field variant="primary"><o-select size="small" v-model="row[column.field]" @change="send(row, column.field)" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)">
							<option v-for="item in percent" :value="item">{{ item }}</option>
						</o-select></o-field>
					</template>
					<template v-else-if="column.field=='cnt' || column.field=='cnt_send'">
					</template>

					<template v-else>
						<o-input v-model="row[column.field]" :ref="column.field" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)" @keyup.enter="send(row, column.field)" size="small" variant="primary" />
					</template>

				</template>

				<template v-else-if="!row[column.field]">
					<template v-if="column.field=='month'">
						<span :class="row.active==1?'':'notactive'">{{ this.monthes[0] }}</span>
					</template>
					<template v-else>
						<div class="empty"></div> <!-- @click="this.focus(row, column.field)" -->
					</template>
				</template>
				<template v-else>
					<span :class="row.active==1?'':'notactive'">{{ this.rowValue(row, column.field) }}</span> <!-- @click="this.focus(row, column.field)" -->
				</template>

			</o-table-column>
		</o-table>

	</div>
</template>

<script>
	import { useProgrammatic } from '@oruga-ui/oruga-next'
	export default {
		name: 'Resends',
		setup() {
			const { oruga } = useProgrammatic()
			return { oruga }
		},
		data() {
			return {
				columns: [
					{ field:'src_offer', label:'Оффер источник' },
					{ field:'month', label:'Дата лида' },
					{ field:'status_id', label:'Статус лида' },
					{ field:'target_offer_id', label:'Оффер цель' },
					{ field:'cap', label:'Капа' },
					{ field:'cnt', label:'Лидов для отправки' },
					{ field:'cnt_send', label:'Отправлено' },
//					{ field:'percent', label:'% переотправки' },
					{ field:'send', label:'Тест', position:'centered' },
					{ field:'active', label:'вкл/откл', position:'centered' },
				],
				data: [],
				target_offers: [],
				src_offers: [],
				percent: ['10', '25', '50', '100'],
				edit: '',
				prev: '',
				addForm: false,
				testMsg: '',
				monthes: ['Все'],
			}
		},
		methods: {
			rowValue(row, field) {
				return (field == 'target_offer_id') ? this.target_offers[row[field]].offer : (field == 'status_id') ? this.$root.statuses[row[field]] : row[field]
			},
			active(id) {
				this.$root.loadData({'id':id}, this, (dt) => {
					this.data = dt['resends']
				})
			},
			send(row, field) {
				if(field == 'target_offer_id') this.data[0].cap = this.target_offers[row.target_offer_id].cap
				if(!this.addForm)
					this.$root.loadData({'id':row.id, 'field':field, 'value':row[field]}, this, (dt) => {
						this.data = dt['resends']
						this.edit = ''
					})
			},
			add(src_offer, status_id, month) {
				if(!Object.keys(this.target_offers).length) {
					this.oruga.notification.open({ message:'Сначала задай офферы цели', duration:10000, position:"top", variant:"danger", closable:true, });
					return
				}
				this.edit = '';
				if(!this.addForm) {
					if(!month) month = this.monthes[0]
					const newRow = {id:-1, active:1, status_id:(status_id ? status_id : 3), month:month, src_offer:src_offer, percent:this.percent[this.percent.length-1], target_offer_id:Object.keys(this.target_offers)[0], cap:(Object.keys(this.target_offers).length ? this.target_offers[Object.keys(this.target_offers)[0]].cap : 0) }
					this.data.unshift(newRow)
				} else {
					var row = this.data[0]
					this.$root.loadData(row, this, (dt) => {
						this.data = dt['resends']
					})
				}
				this.addForm = !this.addForm
			},
			focus(row, field) {
				this.prev = row[field]
				this.edit = field + row.id
				if(this.addForm) {
					this.data.shift()
					this.addForm = false
				}
			},
			blur(row, field) {
				if(!this.addForm) {
					row[field] = this.prev
					this.edit = ''
				}
			},
			testSend(id) {
				this.$root.loadData({'method':'send', 'test_id':id}, this, (dt) => {
					this.oruga.notification.open({ message:dt, duration:60000, position:"top", variant:"success", closable:true, });
				})
			},
		},
		mounted() {
			const date = new Date
			let year = 2023
			let month = 10
			while(year !=date.getFullYear() || month != date.getMonth() + 1) {
				if(month != 12) { month++ } else { month = 1; year++ }
				this.monthes.push(year + '-' + (month < 10 ? '0' : '')+month)
			}
			this.$root.loadData(null, this, (dt) => {
				this.target_offers = dt['target_offers']
				this.src_offers = dt['src_offers']
				this.data = dt['resends']
				if(this.$route.query.src_offer) this.add(this.$route.query.src_offer, this.$route.query.status_id, this.$route.query.column == 'src_offer' ? 'Все' : this.$route.query.column)
			})
		},
	}

</script>

<style>

</style>
