<template>

	<div>

		<div class="tools">
			<div class="filters">
				<o-field label="Вертикаль" variant="primary">
					<o-select size="small" v-model="vertical_id" @change="loadData">
						<option v-for="(item, key) in this.$root.verticals" :value="key">
							{{ item }}
						</option>
					</o-select>
				</o-field>
				<o-field label="Статус" variant="primary">
					<o-select size="small" v-model="status_id" @change="loadData">
						<option v-for="(item, key) in this.$root.statuses" :value="key">
							{{ item }}
						</option>
					</o-select>
				</o-field>
			</div>
		</div>

		<o-table :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true :sort-icon="'arrow-up'">
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">
				<p @click="this.$router.push({ path:'/resend', query:{ src_offer:row.src_offer, status_id:this.status_id, column:column.field } })">{{ row[column.field] }}</p>
			</o-table-column>
		</o-table>

	</div>
</template>

<script>
	export default {
		name: 'LeadsStat',
		data() {
			return {
				column_offer: [{ field:'src_offer', label:'Офер', sortable:true, searchable:true, }],
				columns: [],
				data: [],
				vertical_id: 1,
				status_id: '0',
			}
		},
		methods: {
			loadData() {
				this.$root.loadData({vertical_id:this.vertical_id, status_id:this.status_id}, this, (dt) => {
				console.log(dt)
					this.columns = this.column_offer
					this.columns = this.columns.concat(dt.months)
					this.data = dt.stat
				})
			},
			focus(row, field) {
			},
		},
		mounted() {
			this.loadData()
		},
	}

</script>

<style>

</style>
