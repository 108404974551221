<template>
<div class="finance">

	<div class="transfers">
	<o-field label="Выводы с ПП" variant="primary">
		<o-button @click="this.add_send()" variant="primary" size="small" style="margin-left:1em; height:24px;" :class="(this.add)?'':'is-outlined'">{{(this.add)?'Сохранить':'Вывод'}}</o-button>
		<o-button @click="this.add_send(true)" variant="primary" size="small" style="margin-left:1em; height:24px;" :class="(this.addc)?'':'is-outlined'">{{(this.addc)?'Сохранить':'Компенсация'}}</o-button>
	</o-field>

	<o-table :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true :sort-icon="'arrow-up'" :paginated=true :per-page="perpage" :pagination-simple=true variant="primary">
		<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">


			<template v-if="row.edit && column.field=='amount'">
				<o-input v-model="this.new_row[column.field]" ref="amount" size="small" maxlength=6 :variant="(this.danger)?'danger':'primary'" />
			</template>
			<template v-else-if="row.edit && column.field=='aff'">
				<o-field variant="primary"><o-select size="small" v-model="this.new_row[column.field]"><option v-for="item in affs" :value="item.aff">{{ item.aff }}</option></o-select></o-field>
			</template>
			<template v-else-if="row.edit && column.field=='target'">
				<template v-if="!this.addc">
					<o-field variant="primary"><o-select size="small" v-model="this.new_row[column.field]" ><option v-for="item in targets" :value="item">{{ item }}</option></o-select></o-field>
				</template>
				<template v-else>
					{{ new_row[column.field] }}
				</template>
			</template>
			<template v-else-if="row.edit && column.field=='date_send'">
				<o-datetimepicker v-model="this.new_row[column.field]" size="small" variant="primary" />
			</template>
			<template v-else-if="row.edit && column.field=='date_get' && !this.addc">
				<o-datepicker v-model="this.new_row[column.field]" size="small" variant="primary" />
			</template>

			<template v-else-if="column.field=='date_get' && !row.date_get && !this.addc">
				<o-datepicker v-model="row[column.field]" @update:modelValue="this.edit_get(row)" size="small" variant="primary" />
			</template>
			<template v-else-if="column.field=='status'">
				<template v-if="row.edit">
					<o-icon pack="fas" @click="this.data.shift(), this.add=false, this.addc=false" icon="close" size="small" variant="danger" />
				</template>
				<template v-else>
					<o-icon pack="fas" :icon="row.date_get?'check':'clock'" size="small" :variant="row.date_get?'primary':'info'" />
				</template>
			</template>

			<template v-else-if="row.edit && column.field=='balance'">
			</template>

			<template v-else>
				{{ (row[column.field] ? row[column.field] : '') + this.$root.units(column.field)}}
			</template>

		</o-table-column>
	</o-table>
	</div>

	<div class="balances">
	<o-field label="Баланс" variant="primary">&nbsp;</o-field>
	<o-table :data="affs" :bordered=true :striped=true :narrowed=true :hoverable=true :sort-icon="'arrow-up'" :paginated=false variant="primary">
		<o-table-column v-for="(column, idx) in columns_aff" :key="idx" v-bind="column" v-slot="{ row }">
			<template v-if="column.field == 'aff'">
				<a :href="row.url" target=_blank>{{ row[column.field] }}</a>
			</template>
			<template v-else-if="column.field == 'balance' && this.edit_aff == row.aff">
				<o-input v-model="this.balance" icon-right="check" icon-right-clickable  @icon-right-click="this.edit_balance(row)" ref="balance" size="small" maxlength=6 variant="primary" />
			</template>
			<template v-else>
				<span @click="this.balance=row.balance, this.edit_aff=row.aff">{{ row[column.field] + this.$root.units(column.field) }}</span>
			</template>
		</o-table-column>
	</o-table>
	</div>
</div>
</template>

<script>
	import Interval from './Interval.vue'
	export default {
		name: 'Affp',
		components: {
			Interval
		},
		data() {
			return {
				columns: [
					{ field:'aff', label:'Партнерка', searchable:true },
					{ field:'amount', label:'Сумма', position:'right' },
					{ field:'target', label:'Куда', searchable:true },
//					{ field:'balance', label:'Баланс', position:'right' },
					{ field:'date_send', label:'Запрос' },
					{ field:'date_get', label:'Получено', type:'date' },
					{ field:'status', label:'Статус', position:'centered' },
				],
				data: [],
				perpage: 25,
				add: false,
				addc: false,
				new_row: [],
				affs: [],
				targets: ['Trust', 'Binance', 'BRO', 'Multicards'],
				danger: false,
				edit_aff: '',
				balance: 0,
				columns_aff: [
					{ field:'aff', label:'Партнерка' },
					{ field:'balance', label:'Баланс', position:'right' },
				],
			}
		},
		methods: {
			add_send(compensation) {
				if((this.add && compensation) || (this.addc && !compensation)) {
					this.new_row = []
					this.danger = false
					this.add = false
					this.addc = false
					this.data.shift()
				}
				if(!this.add && !this.addc) {
					this.new_row.date_send = new Date()
					this.new_row.aff = this.affs[0].aff
					this.new_row.target = compensation ? 'Компенсация' : this.targets[0]
					this.data.unshift({'edit':true})
					if(!compensation)
						this.add = true
					else
						this.addc = true
				} else {
					if(isFinite(this.new_row.amount) && this.new_row.amount > 0 && this.new_row.aff) {
						this.new_row.date_send = this.$root.formatDate(this.new_row.date_send)
						this.new_row.date_get = this.$root.formatDate(this.new_row.date_get)
						this.$root.loadData({'method':'add_send', 'aff':this.new_row.aff, 'amount':this.new_row.amount, 'target':this.new_row.target, 'date_send':this.new_row.date_send, 'date_get':this.new_row.date_get}, this, (dt) => {
							console.log(dt)
							this.data = dt.transfers
							this.affs = dt.affs
							this.add = false
							this.addc = false
						})
						this.new_row = []
						this.danger = false
					} else {
						this.danger = true
					}
				}
			},
			edit_get(row) {
				row.date_get = this.$root.formatDate(row.date_get)
				this.$root.loadData({'method':'edit_get', 'id':row.id, 'date_get':row.date_get}, this, (dt) => {
					this.data = dt.transfers
					console.log(dt)
				})
			},
			edit_balance(row) {
				if(isFinite(this.balance)) {
					this.$root.loadData({'method':'edit_balance', 'aff':row.aff, 'shift':this.balance-row.balance}, this, (dt) => {
						this.affs = dt.affs
						this.edit_aff = ''
						console.log(dt)
					})
				}
			},
		},
		computed: {
		},
		mounted() {
			this.$root.loadData(null, this, (dt) => {
				console.log(dt)
				this.affs = dt.affs
				this.data = dt.transfers
			})
		},
	}
</script>


<style>
.finance{ display:flex; }
.transfers{ width:70%; margin-right:10px; }
.transfers .button{ margin-bottom:0!important; }

.balances{ width:29%;}

th .control .input{ font-size:9px; }
td .input{  height:24px; }
</style>
