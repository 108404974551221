<template>

<o-field variant="primary">
	<o-select size="small" v-model="offer" @change="change">
		<option v-for="(item, key) in offers" :value="key">
			{{ item }}
		</option>
	</o-select>
</o-field>

</template>

<script>
	export default {
		name: 'AnalyticsOffers',
		data() {
			return {
				offer: '',
				offers: {
				},
			}
		},
		emits: ['change'],
		methods: {
			change() {
				this.$emit('change', {'offer':this.offer})
			},
		},
		mounted() {
			this.$root.loadData(null, this, (dt) => {
			console.log(dt)
				Object.assign(this.offers, dt)
				this.offer = Object.values(this.offers)[0]
				this.change()
			})
		},
	}
</script>
