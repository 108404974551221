<template>

	<div class="users">
		<o-field variant="primary">
			<o-button @click="this.add()" variant="primary" outlined size="small">
				<span v-if="!this.addForm">Добавить</span>
				<span v-if="this.addForm">Сохранить</span>
			</o-button>
			<template v-if="this.addForm">
				<o-button @click="addForm=false, this.data.shift()" variant="warning" outlined size="small">Отмена</o-button>
			</template>
		</o-field>

		<o-table :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true>
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">

				<template v-if="column.field=='branch'">

					<o-field variant="primary">
						<o-select size="small" v-model="row[column.field]" @change="branch(row)">
							<option v-for="(item, key) in branches" :value="key">
								{{ item }}
							</option>
						</o-select>
					</o-field>


				</template>

				<template v-else-if="column.field=='active'">
					<o-icon pack="fas" :icon="row.active==1?'check':'xmark'" size="small" :variant="row.active==1?'primary':'warning'" @click="active(row.id)" />
				</template>

				<template v-else-if="this.edit==column.field+row.id || (this.addForm && row.id==-1)">
					<o-input v-model="row[column.field]" :ref="column.field" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)" @keyup.enter="send(row.id, column.field, row[column.field])" size="small" variant="primary" />
				</template>

				<template v-else-if="!row[column.field]">
					<div @click="this.focus(row, column.field)" class="empty"></div>
				</template>
				<template v-else>
					<span @click="this.focus(row, column.field)" :class="row.active==1?'':'notactive'">{{ row[column.field] }}</span>
				</template>


			</o-table-column>
		</o-table>

	</div>
</template>

<script>
	export default {
		name: 'Users',
		data() {
			return {
				columns: [
					{ field:'name', label:'Логин' },
					{ field:'branch', label:'Филиал' },
					{ field:'sub_id_2', label:'sub_id_2' },
					{ field:'username', label:'tg' },
					{ field:'chat_id', label:'tg chat_id' },
					{ field:'group_chat_id', label:'tg group_chat_id' },
					{ field:'email', label:'email' },
					{ field:'kt_api_key', label:'KT API key' },
					{ field:'password', label:'пароль' },
					{ field:'active', label:'вкл/откл', position:'centered' },
				],
				data: [],
				branches: {
					'':'unionaff',
					'south':'south',
				},
				edit: '',
				prev: '',
				addForm: false,
			}
		},
		methods: {
			active(id) {
				this.$root.loadData({'id':id}, this, (dt) => {
					this.data = dt
				})
			},
			branch(row) {
				if(!this.addForm) {
					this.$root.loadData({'id':row.id, 'field':'branch', 'value':row.branch}, this, (dt) => {
						this.data = dt
						this.edit = ''
					})
				}
			},
			send(id, field, value) {
				if(!this.addForm)
					this.$root.loadData({'id':id, 'field':field, 'value':value}, this, (dt) => {
						this.data = dt
						this.edit = ''
					})
			},
			add() {
				this.edit = '';
				if(!this.addForm) {
					const newUser = {id:-1, active:1}
					this.data.unshift(newUser)
				} else {
					this.$root.loadData(this.data[0], this, (dt) => {
						this.data = dt
					})
				}
				this.addForm = !this.addForm
			},
			focus(row, field) {
				this.prev = row[field]
				this.edit = field+row.id
				if(field == 'password') {
					row.password = ''
				}
				if(this.addForm) {
					this.data.shift()
					this.addForm = false
				}
			},
			blur(row, field) {
				if(!this.addForm) {
					row[field]=this.prev
					this.edit=''
				}
			},
		},
		mounted() {
			this.$root.loadData(null, this, (dt) => { console.log(dt)
			this.data = dt })
		},
	}

</script>

<style>

</style>
