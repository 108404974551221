<!-- https://stackoverflow.com/questions/62705389/vue-input-dropdown-simple -->
<template>

	<div class="leads">
		<o-field variant="primary">
			<o-button @click="this.add()" variant="primary" outlined size="small">
				<span v-if="!this.addForm">Добавить</span>
				<span v-if="this.addForm">Сохранить</span>
			</o-button>
			<template v-if="this.addForm">
				<o-button @click="addForm=false, this.data.shift()" variant="warning" outlined size="small">Отмена</o-button>
			</template>
		</o-field>

		<datalist id="kt_groups">
			<option v-for="(item, key) in kt_groups" :value="key" />
		</datalist>

		<o-table :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true>
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">

				<template v-if="column.field=='active'">
					<o-icon pack="fas" :icon="row.active==1?'check':'xmark'" size="small" :variant="row.active==1?'primary':'warning'" @click="active(row.id)" />
				</template>

				<template v-else-if="this.edit==column.field+row.id || (this.addForm && row.id==-1)">

					<template v-if="column.field=='kt_group'">
						<o-input list="kt_groups" v-model="row['kt_group']" variant="primary" @change="row['kt_group'] = this.changeGroup(row)"  />
					</template>
					<template v-else>
						<o-field variant="primary">
							<o-select size="small" v-model="row['vertical']">
								<option v-for="(item, key) in verticals" :value="key">
									{{ item }}
								</option>
							</o-select>
						</o-field>
					</template>


				</template>

				<template v-else>
					<template v-if="row.active==1">

						<template v-if="column.field=='kt_group'">
							<o-input list="kt_groups" v-model="row['kt_group']" variant="primary" @change="row['kt_group'] = this.changeGroup(row)" />
						</template>
						<template v-else>
							<o-field variant="primary">
								<o-select size="small" v-model="row['vertical']" @change="this.send(row)">
									<option v-for="(item, key) in verticals" :value="key">
										{{ item }}
									</option>
								</o-select>
							</o-field>
						</template>

					</template>
					<template v-else>
						<span class="notactive"> {{ row[column.field] }}</span>
					</template>
				</template>


			</o-table-column>
		</o-table>

	</div>
</template>

<script>
	export default {
		name: 'Leads',
		data() {
			return {
				columns: [
					{ field:'kt_group', label:'Группа оферов' },
					{ field:'vertical', label:'Вертикаль' },
					{ field:'active', label:'вкл/откл', position:'centered' },
				],
				data: [],
				edit: '',
				prev: '',
				addForm: false,
				verticals: {
					'Диабет':'Диабет',
					'Простатит':'Простатит',
					'Гипертония':'Гипертония',
					'Зрение':'Зрение',
					'Похудение':'Похудение',
				},
				kt_groups: [],

			}
		},
		methods: {
			active(id) {
				this.$root.loadData({'method':'settings', 'id':id}, this, (dt) => {
					this.data = dt['settings']
				})
			},
			send(row) {
				if(!this.addForm)
					row['method'] = 'settings'
					row['update'] = 1
					this.$root.loadData(row, this, (dt) => {
						this.data = dt['settings']
						this.edit = ''
					})
			},
			changeGroup(row) {
				var kt_group = this.kt_groups[row['kt_group']]
				row['kt_group_id'] = kt_group
				if(kt_group) {
					if(this.addForm) {
						this.data[0]['kt_group_id'] = kt_group
					} else {
						this.send(row)
					}
					return row['kt_group']
				} else {
					return ''
				}
			},
			add() {
				this.edit = '';
				if(!this.addForm) {
					const newRow = {id:-1, active:1}
					this.data.unshift(newRow)
				} else {
					var row = this.data[0]
					row['method'] = 'settings'
					this.$root.loadData(row, this, (dt) => {
						this.data = dt['settings']
					})
				}
				this.addForm = !this.addForm
			},
			focus(row, field) {
				this.prev = row[field]
				this.edit = field+row.id
				if(this.addForm) {
					this.data.shift()
					this.addForm = false
				}
			},
			blur(row, field) {
				if(!this.addForm) {
					row[field]=this.prev
					this.edit=''
				}
			},
		},
		mounted() {
			this.$root.loadData({'method':'settings'}, this, (dt) => {
				this.kt_groups = dt['kt_groups']
				this.data = dt['settings']
			})
		},
	}

</script>

<style>

</style>
