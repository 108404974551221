<template>
	<o-field label="Лидерборд" variant="primary">
		<Interval @change="this.$root.loadData($event, this, (dt) => { this.data = dt })" />
	</o-field>

 	<o-table :data="data" ref="leaders" :bordered=true :striped=true :narrowed=true :hoverable=true>
		<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column">

			<template #default="props">
				<span v-bind:title="column.field=='name'?props.row['source']:''" v-bind:class="'n'+props.row['num'] + (props.row['source']==this.$root.sub_id_2 ? ' my':'') + (column.field=='profit_confirmed' && props.row['hide'] ? ' hidden':'')">
					{{ props.row[column.field] + this.$root.units(column.field) }}
				</span>
			</template>

		</o-table-column>
	</o-table>
</template>

<script>
	import Interval from './Interval.vue'
	export default {
		name: 'Leaders',
		components: {
			Interval
		},
		data() {
			return {
				columns: [
					{ field:'num', label:'' },
					{ field:'name', label:'Байер' },
					{ field:'profit_confirmed', label:'Прибыль', position:'right' },
					{ field:'roi_confirmed', label:'ROI', position:'right' },
				],
				data: [],
			}
		},
		methods: {
		},
		computed: {
			//data() { return this.$root.data }
		},
		mounted() {
			this.$root.loadData(null, this, (dt) => { this.data = dt })
		},
	}
</script>

<style>
/*
.leaders .gray .select.is-primary select{ width:8em; }
.leaders .gray .select.is-primary:not(:hover)::after{ border-color:gray; }
.leaders .gray .is-primary.input{ border:none; }
.leaders .gray .button.is-primary{ background-color:gray; }
*/
.leaders .hidden{ filter:blur(0.3rem); }
.leaders .my{ font-weight:bold; }
</style>
