<template>
	<div class="tools">
		<div class="filters">
				<Interval @change="this.$root.loadData($event, this, (dt) => { this.data_offers = dt })" />
		</div>
	</div>

 	<o-table :data="data_offers" :bordered=true :striped=true :narrowed=true :hoverable=true :detailed=true :show-detail-icon=false :sort-icon="'arrow-up'" :paginated=true :per-page="perpage" :pagination-simple=true>
		<o-table-column v-for="(column, idx) in columns_offers" :key="idx" v-bind="column">

			<template v-if="column.searchable && !column.numeric" #searchable="props">
				<o-input v-model="props.filters[props.column.field]" placeholder="Search" icon="search" size="small" />
			</template>

			<template #default="props">
				<div @click="toggle(props)">
					<template v-if="column.field=='offer'">
						<a :href="'/admin/?object=offers.preview&id=' + props.row.offer_id" target="_blank">{{ props.row[column.field]  }}</a>
					</template>
					<template v-else>
						{{ props.row[column.field] + this.$root.units(column.field) }}
					</template>
				</div>
			</template>

		</o-table-column>

		<template #detail="props">

			<div class="creos">
				<o-table :data="data[props.index]" :bordered=true :striped=true :narrowed=true :hoverable=true :sort-icon="'arrow-up'">
					<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">

						<template v-if="column.field=='creo'">
							<template v-if="row.type=='video'">
								<video preload="metadata" controls class="creo_offers"><source :src="row.creo"></video>
							</template>
							<template v-else>
								<img :src="row.creo" class="creo_offers" />
							</template>
						</template>
						<template v-else-if="column.field=='type' && row[column.field]">
							<o-icon pack="fas" v-model:icon="row[column.field]" size="small" />
						</template>
						<template v-else>
							{{ row[column.field] + this.$root.units(column.field) }}
						</template>

					</o-table-column>
				</o-table>
			</div>

		</template>

		<template v-slot:bottom-left>
			<o-field variant="primary">
				<o-select size="small" v-model="perpage">
				<option v-for="item in [25,50,100,200,500]" :value="item">
					{{ item }}
				</option>
				</o-select>
			</o-field>
		</template>

	</o-table>
</template>

<script>
	import Interval from './Interval.vue'
	export default {
		name: 'Offers',
		components: {
			Interval
		},
		data() {
			return {
				props:['username'],
				columns: [
					{ field:'creo' },
					{ field:'sub_id_3', label:'Крео' },
					{ field:'sub_id_2', label:'Автор' },
					{ field:'type', label:'Тип' },
					{ field:'date', label:'Дата', sortable:true },
					{ field:'clicks', label:'Клики', sortable:true, numeric:true },
					{ field:'conversions', label:'Конв.', sortable:true, numeric:true },
					{ field:'roi_confirmed', label:'ROI', sortable:true, position:'right' },
					{ field:'cpa', label:'CPA', sortable:true, numeric:true, position:'right' },
					{ field:'cr', label:'CR', sortable:true, numeric:true, position:'right' },
					{ field:'country_code', label:'Страна', sortable:true },
				],
				data: [],
				columns_offers: [
					{ field:'offer', label:'Оффер', sortable:true, searchable:true },
					{ field:'clicks', label:'Клики', sortable:true },
					{ field:'conversions', label:'Конв.', sortable:true },
					{ field:'approve', label:'Апрув', sortable:true },
					{ field:'cost', label:'Расход', sortable:true, position:'right' },
					{ field:'profit_confirmed', label:'Прибыль', sortable:true, position:'right' },
					{ field:'roi_confirmed', label:'ROI', sortable:true, position:'right' },
					{ field:'sales', label:'Продажи', sortable:true },
					{ field:'cpa', label:'CPA', sortable:true, position:'right' },
					{ field:'cr', label:'CR', sortable:true, position:'right' },
				],
				data_offers: [],
				perpage: 25,
			}
		},
		methods: {
			toggle(props) {
				this.$root.loadData({ offer_id:props.row.offer_id }, this, (dt) => { this.data[props.index] = dt })
				props.toggleDetails(props.row)
			},
		},
		mounted() {
			this.$root.loadData(null, this, (dt) => { this.data_offers = dt })
		},
	}
</script>

<style>
.creos td:nth-child(1) { width:20%; }
.offers{ font-size:0.9em; }
td[data-label="Оффер"], td[data-label="Крео"]{ width:200px!important; white-space:normal; }
</style>
