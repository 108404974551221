<template>
	<div class="header">
		<h1 class="title is-5">{{ this.$route.name }}</h1>
		<div style="display:flex; flex-direction:column;">
			<p style="font-size:1.5em; line-height:0">KPI</p>
			<p style="font-size:0.8em; line-height:0"><br>сегодня</p>
		</div>
		<div>
			<p>Spend<br>{{ this.data.cost }} $</p>
		</div>
		<div>
			<p>Профит<br>{{ this.data.profit_confirmed }} $</p>
		</div>
		<div>
			<p>ROI<br>{{ this.data.roi_confirmed }}%</p>
		</div>
		<div class="user">
			<template>
			<p>{{ this.$root.user }}</p>
			</template>
			<img :src="this.$root.ava" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'Header',
	data() {
		return {
			interval: 'today',
			data: { cost:'', profit_confirmed:'', roi_confirmed:'', },
		}
	},
	computed: {
		//indicators() { return this.$root.data[0] }
	},
	methods: {
	},
	mounted() {
		this.$root.loadData(this.$root.admin ? null : {sub_id_2:this.$root.sub_id_2}, this, (dt) => { this.data = dt })
	},
}

</script>

<style>
	.header{ height:50px; display:flex; justify-content:flex-end; margin-bottom:10px; }
	.header div{ display:flex; margin-left:20px; }
	.header div.user{ margin-left:60px; }
	.header p{ margin:auto; }
	.header h1{ margin:auto auto auto 0; }
	.user img{ height:100%; width:auto; margin:0 0 0 20px; border-radius:50%; border:2px solid gray; padding:2px;}
</style>
