<template>
	<div>
		<o-field label="Новости CRM" variant="primary">&nbsp;</o-field>
		<o-table :data="data.crm" :bordered=true :striped=true :narrowed=true :hoverable=true :showHeader=false>
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">
				<span class="date">{{ row['date'] }}</span> {{ row[column.field] }}
			</o-table-column>
		</o-table>
	</div>

	<div>
		<o-field label="Новости" variant="primary">
			<o-button v-if="this.$root.admin" @click="newsAdd=true" variant="primary" outlined size="small">Добавить</o-button>
		</o-field>
		<div v-if="newsAdd" class="newsadd">
			<o-input v-model="newsAddText" maxlength="400" type="textarea" />
			<o-button @click="newsSend" variant="primary" size="small">Отправить</o-button>
			<o-button @click="newsAdd=false" variant="primary" outlined size="small">Отмена</o-button>
		</div>

		<o-table :data="data.news" :bordered=true :striped=true :narrowed=true :hoverable=true :showHeader=false>
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">
				<span class="date">{{ row['date'] }}</span> {{ row[column.field] }}
			</o-table-column>
		</o-table>
	</div>
</template>

<script>
	export default {
		name: 'News',
		data() {
			return {
				columns: [
					{ field:'text'},
				],
				data: {},
				newsAdd: false,
				newsAddText: '',
			}
		},
		methods: {
			newsSend() {
				if(this.newsAddText) {
					this.axios.post(this.$root.api, {action:this.$.type.name, text:this.newsAddText}, {headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
						if(!response.data.error) {
							this.newsAddText = ''
							this.newsAdd = false
							this.$root.loadData(null, this, (dt) => { this.data = dt })
						} else {
							console.log(response.data)
						}
					}).catch((error) => { console.log(error) });
				}
			},
		},
		mounted() {
			this.$root.loadData(null, this, (dt) => { this.data = dt })
		},
	}

</script>

<style>
.news{ margin-right:0; display:flex; }
.news>div{ width:49%; margin-left:10px;}
.news .b-table{ width:100%; margin:0;}

.news td{ white-space:normal; }
.news .date { background-color:#aaa; color:white; font-size:0.7em; padding:0 5px; }

.newsadd .control{ width:100%}
.newsadd .button{ margin:6px 1em 6px 0!important; }

</style>
