<template>

<div v-html="html"></div>

</template>

<script>
	export default {
		name: 'Html',
		data() {
			return {
				html: '<p>Loading...</p>'
			}
		},
		methods: {
		},
		mounted() {
			this.axios.get('prize/index.html').then((response) => {
				this.html = response.data
			})
		},
	}
</script>
