<template>

<o-field variant="primary">
	<o-select size="small" v-model="sub_id_2" @change="change">
		<option v-for="(item, key) in sub_id_2s" :value="key">
			{{ item }}
		</option>
	</o-select>
</o-field>

</template>

<script>
	export default {
		name: 'Sub_id_2',
		data() {
			return {
				sub_id_2: '',
				sub_id_2s: {
					'':'Все',
				},
			}
		},
		emits: ['change'],
		methods: {
			change() {
				this.$emit('change', {'sub_id_2':this.sub_id_2})
			},
		},
		mounted() {
			if(this.$root.admin) {
				this.$root.loadData(null, this, (dt) => {	this.sub_id_2s = Object.assign(this.sub_id_2s, dt) })
			} else {
				this.sub_id_2 = this.$root.sub_id_2
				this.sub_id_2s = {}
				this.sub_id_2s[this.sub_id_2] = this.sub_id_2
			}
		},
	}
</script>
